<template>
    <section>
         <b-img 
          :src="require('@/assets/images/imgs/KE-Logo.png')"
          fluid
          style="max-width: unset !important;
            width: 35%; !important;margin-left:30%;margin-top:30px"
          alt="logo"
        />
        <p style="font-size:44px;text-align:center;margin-top:40px;color:#4A4852;font-weight:500">Please choose your order type</p>
        <div style="display:flex;width:90%;margin-left:5%;margin-top:60px;">
            <div style="border:3px solid #E9E9EB;width:48%;padding:50px;border-radius:12px;text-align:center">
                <b-img 
                :src="require('@/assets/images/imgs/pngkit_truck-vector.png')"
                fluid
                style="max-width: unset !important;
                    width: 28%; !important;"
                alt="logo"
                />
                <p style="font-size:20px;text-align:center;margin-top:30px;font-weight:500">Single Delivery Service / 單次派送服務 </p>
                <b-button variant="primary" :to="{ name: 'create-order' }" :pressed="true" size="lg" style="margin-top:30px;">PLACE ORDER</b-button>
            </div>
            <div  style="border:3px solid #E9E9EB;width:48%;padding:28px;margin-left:4%;border-radius:12px;text-align:center">
                <b-img 
                :src="require('@/assets/images/imgs/return-box.png')"
                fluid
                style="max-width: unset !important;
                    width: 22%; !important;"
                alt="logo"
                />
                <p style="font-size:20px;text-align:center;margin-top:30px;font-weight:500">Shipment Pickup from Consignee / 目的地取件</p>
                <b-button variant="primary" :to="{name:'return-order'}" class="pl-or" :pressed="true" size="lg"  style="margin-top:30px;">PLACE ORDER</b-button>
            </div>
        </div>
    </section>
</template>
<script>
import {
  BImg,BButton
} from 'bootstrap-vue'
export default {
    components:{
        BImg,BButton
    },
    data(){
        return{

        }
    }
}
</script>
<style>
</style>